import React from "react";
import  { useState, useEffect } from "react";

export const Header = (props) => {
  const [isH1Hovered, setIsH1Hovered] = useState(false);
  const [isPHovered, setIsPHovered] = useState(false);

  const baseStyle = {
    cursor: 'pointer',
  };


  const h1Style = isH1Hovered ? { ...baseStyle } : baseStyle;
  const pStyle = isPHovered ? { ...baseStyle } : baseStyle;

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
              <h1
                  onClick={props.onClick}
                  style={h1Style}
                  onMouseEnter={() => setIsH1Hovered(true)}
                  onMouseLeave={() => setIsH1Hovered(false)}
                >
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p
                  onClick={props.onClick}
                  style={pStyle}
                  onMouseEnter={() => setIsPHovered(true)}
                  onMouseLeave={() => setIsPHovered(false)}
                >
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
